<template>
  <div>
    <template v-if="item.Value.Name">
      <v-card elevation="0" max-width="700" class="mb-2">
        <v-card-text class="px-0">
          <v-img
            contain
            max-height="500"
            :src="
              serverUrl +
              '/images/' +
              websiteId +
              '/' +
              item.Value.Name +
              '?token=' +
              userToken
            "
          >
            <v-app-bar
              flat
              dense
              color="rgba(0, 0, 0, 0)"
              v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="item.AddAltTags"
                depressed
                small
                color="primaryorange"
                icon
                @click="hover = !hover"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                depressed
                small
                color="primaryred"
                icon
                @click="deleteImage(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-app-bar>
          </v-img>
          <v-expand-transition>
            <v-layout v-if="hover" row wrap>
              <template
                v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
              >
                <v-layout
                  align-center
                  v-if="item.AddAltTags || item.AddAltTags == null"
                >
                  <v-select
                    v-if="item.Translate || item.Translate == undefined"
                    :items="itemLanguages"
                    v-model="itemDefaultLanguage"
                    single-line
                    class="altSelect-lang"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-select>
                  <template
                    v-for="(translatedValue, translatedValueIndex) in item.Value
                      .Alt"
                  >
                    <v-text-field
                      v-if="itemDefaultLanguage == translatedValueIndex"
                      :key="translatedValueIndex"
                      :class="
                        item.Translate || item.Translate == undefined
                          ? 'ml-4 mt-2'
                          : ' mt-2'
                      "
                      placeholder="Alt tag"
                      v-model="item.Value.Alt[translatedValueIndex]"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-text-field>
                  </template>
                </v-layout>
              </template>
              <template v-else>
                {{ item.Value.Alt[currentLanguage] }}
              </template>
            </v-layout>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </template>

    <!-- If no image was saved -->
    <template v-else-if="!item.ReadOnly || editItemAdmin">
      <v-layout row wrap class="mt-2">
        <v-flex lg6 sm12>
          <VueCropper
            v-if="getSelectedImageToCrop"
            class="mb-3"
            ref="cropper"
            :src="getSelectedImageToCrop"
            :initialAspectRatio="Number(item.Width) / Number(item.Height)"
            :aspectRatio="Number(item.Width) / Number(item.Height)"
            :cropBoxResizable="item.Width || item.Height ? false : true"
          >
          </VueCropper>
        </v-flex>
      </v-layout>

      <input
        :ref="'fileInput' + objectName"
        class="fileInput"
        type="file"
        @change="onCroppedImageSelect"
      />
      <v-btn
        depressed
        class="ml-0 mt-3 mr-3 edit-page-action-button"
        @click="$refs['fileInput' + objectName].click()"
        color="primary"
        >{{ $t("Choose an image") }}</v-btn
      >
      <v-btn
        depressed
        color="primaryblue"
        class="ml-0 mt-3 white--text edit-page-action-button"
        :disabled="!file || uploading"
        :loading="uploading"
        v-on:click="submitCroppedImage(item)"
        >{{ $t("Upload") }}<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
      >
    </template>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-text-field
            :label="$t('Width in pixels')"
            v-model="item.Width"
            class="float-left mr-3"
            hide-details="auto"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('Height in pixels')"
            v-model="item.Height"
            class="float-left mr-3 mt-5"
            hide-details="auto"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.Translate"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Translate item')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.AddAltTags"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Add alt tags')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: { VueCropper },
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      serverUrl: this.$helpers.getServerUrl(),
      uploading: false,
      file: null,
      selectedImageToCrop: null,
      uploadError: null,
      hover: null,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale();
    },
    getSelectedImageToCrop() {
      return this.selectedImageToCrop;
    },
  },
  methods: {
    deleteImage(item) {
      item.Value.Name = "";
      this.selectedImageToCrop = null;
    },
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    onCroppedImageSelect(e) {
      this.file = e.target.files[0];
      this.mime_type = this.file.type;

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedImageToCrop = event.target.result;
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };
        reader.readAsDataURL(this.file);
      }
    },
    submitCroppedImage(item) {
      this.uploading = true;
      this.$emit("update:saving", true);

      this.$refs.cropper
        .getCroppedCanvas({ width: this.item.Width })
        .toBlob((blob) => {
          let formData = new FormData();
          formData.append("files", blob, this.file.name);

          this.$request.post(
            "/content/imageupload/" + this.pageId,
            formData,
            (res) => {
              this.uploading = false;
              this.$emit("update:saving", false);
              this.$refs["fileInput" + this.objectName].value = null;

              if (res.success) {
                this.uploadError = "";
                item.Value.Name = res.data[0];
                this.file = null;
              } else {
                this.uploadError = res.message;
                const toaster = {
                  message: res.message,
                  timeout: false,
                  type: "ERROR",
                  toastClassName: ["toaster-color-error", "toaster-layout"],
                  bodyClassName: ["toaster-text-layout"],
                  icon: "mdi-alert-octagon",
                };
                this.$store.dispatch("setToaster", toaster);
              }
            }
          );
        }, this.mime_type);
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.imageContainer {
  position: relative;
  max-width: 100%;
  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}
.pageImage {
  max-width: 100%;
  max-height: 500px;
}
</style>